<template>
  <v-form @submit.prevent="update">
    <v-card max-width="460" class="px-4 py-8 mx-auto my-10 white" flat>
      <v-row class="mb-10">
        <v-col cols="3">
          <loader :loading="loading.avatar">
            <v-avatar
              color="grey"
              size="80px"
              @click="$refs.fileManager.click()"
            >
              <template v-if="avatarUrl">
                <img :src="avatarUrl" :alt="profile.name" />
              </template>
              <template v-else>
                <img
                  v-if="userHasAvatar"
                  :src="profile.avatar"
                  :alt="profile.name"
                />
                <span v-else class="white--text text-h5 text-uppercase">
                  {{ profile.name | trim(2) }}
                </span>
              </template>
            </v-avatar>
          </loader>
        </v-col>
        <v-col cols="9" class="d-flex flex-column justify-center">
          <input
            type="file"
            accept="image/*"
            class="d-none"
            ref="fileManager"
            @change="getProfileImage"
          />
          <p class="primary--text" @click.prevent="$refs.fileManager.click()">
            +Add Profile Image
          </p>
          <p class="caption">size 500 x 500 px</p>
        </v-col>
      </v-row>

      <p class="font-weight-medium mt-8">Personal Info</p>

      <v-row>
        <v-col cols="4">
          <v-subheader>Name</v-subheader>
        </v-col>
        <v-col cols="8">
          <InputGroup v-model="profile.name" />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="4">
          <v-subheader>Email</v-subheader>
        </v-col>
        <v-col cols="8">
          <InputGroup v-model="profile.email" />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="4">
          <v-subheader>Phone Number</v-subheader>
        </v-col>
        <v-col cols="8">
          <InputGroup v-model="profile.contact" />
        </v-col>
      </v-row>

      <p class="font-weight-medium my-4">Company Details</p>

      <v-row>
        <v-col cols="4">
          <v-subheader>Street Address</v-subheader>
        </v-col>
        <v-col cols="8">
          <InputGroup v-model="profile.address" />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="4">
          <v-subheader>Country</v-subheader>
        </v-col>
        <v-col cols="8">
          <InputGroup v-model="profile.country" />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="4">
          <v-subheader>City</v-subheader>
        </v-col>
        <v-col cols="8">
          <InputGroup v-model="profile.city" />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="4">
          <v-subheader>Zipcode</v-subheader>
        </v-col>
        <v-col cols="8">
          <InputGroup v-model="profile.postal_code" />
        </v-col>
      </v-row>

      <v-row v-if="false">
        <v-col cols="4">
          <v-subheader>Opening Hours</v-subheader>
        </v-col>
        <v-col cols="8">
          <p class="primary--text mt-2">+ Add Opening Hours</p>
        </v-col>
      </v-row>

      <Button
        color="primary"
        :loading="loading.user"
        type="submit"
        block
        class="mt-8"
      >
        Update
      </Button>
    </v-card>
  </v-form>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions } = createNamespacedHelpers("user");

import { InputGroup, Button } from "@/components";
import {
  validEmail,
  required,
  minPasswordLength
} from "@/helpers/form-validations";

export default {
  name: "profile",

  components: {
    InputGroup,
    Button
  },

  data() {
    return {
      localUser: {},
      avatarLoading: false,
      avatarFile: {},
      avatarUrl: "",
      isValid: false
    };
  },

  computed: {
    ...mapState(["user", "loading"]),

    profile() {
      return this.user;
    },

    userHasAvatar() {
      const exts = ["jpg", "jpeg", "svg", "tif", "png"];
      const avatar = this.profile.avatar;
      if (avatar) {
        return exts.includes(avatar.slice(avatar.lastIndexOf(".") + 1));
      }
      return false;
    },

    rules() {
      return {
        validEmail,
        required,
        minPasswordLength
      };
    }
  },

  methods: {
    ...mapActions(["UPDATE_PROFILE", "UPLOAD_AVATAR"]),

    update() {
      const user = { ...this.profile };
      if (this.user.email === user.email) {
        delete user.email;
      }
      this.UPDATE_PROFILE({ ...user });
    },

    async getProfileImage(e) {
      const target = e.target;
      const file = target.files;

      if (file && file.length) {
        this.avatarFile = file[0];
      }

      let fd = new FormData();
      fd.append("media[1]", this.avatarFile);
      const result = await this.UPLOAD_AVATAR(fd);
      if (result) {
        this.avatarUrl = URL.createObjectURL(file[0]);
      } else
        this.$notify({
          group: "notification",
          title: "Error",
          text: "Image not uploaded please try again",
          type: "error",
          duration: 5000
        });
      this.$refs.fileManager.value = "";
    }
  },
  created() {}
};
</script>

<style scoped lang="scss">
.primary--text {
  margin-bottom: 8px;
  cursor: pointer;
}
[class^="col"] {
  padding: 0;
}
.row {
  margin: -12px 0 !important;
}
</style>
